import React from 'react';

import '../scss/location.scss';
import '../scss/cardio_respiratory_health.scss';
import RightSideComponent from '../components/right_side_component';

const LOCATION_SERVICES = [
    'Immunizations',
    'Annual physical exams',
    'Cancer screenings',
    'Additional healthcare services based on individual needs',
];

const TOP_PRIMARY_CARE_SERVICES = [
'Preventive care',
 'Chronic disease management',
 'Health promotion',
]


const LocationPage = ({ location }) => {
    return (
        <section class="services-details-area ptb-100 location-page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="services-details-desc">                          
                            <h3>Doctor Titsuville</h3>

                            <p>
                                Our <b>primary care doctor in Titusville</b> offers a wide range of adult primary care services
                                tailored to meet our patients's diverse needs.
                            </p>

                            <br />
                            <b>Personalized Care for Every Stage of Life:</b>
                            <p>● Titusville medical team provides specialized services for all ages, from adolescence to
                                adulthood and beyond.</p>
                            <p>● Experienced primary care doctors address unique health concerns for each age group</p>

                            <p>Services offered include:</p>
                            <div class="services-details-features">
                                <div class="row">
                                    <div class="p-2">
                                        <ul class="services-features-list">
                                            {LOCATION_SERVICES.map((_) => (
                                                <li key={_}>
                                                    <i class="flaticon-check-mark"></i> {_}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                 </div>
                            </div>

                            <b>Top Primary Care Clinic in Titusville:</b>
                            <p>● Committed to delivering exceptional healthcare to the Titusville community and
                            surrounding areas.</p>
                            <p>Skilled primary care physicians with expertise in:</p>
                            <div class="services-details-features">
                                <div class="row">
                                    <div class="p-2">
                                        <ul class="services-features-list">
                                            {TOP_PRIMARY_CARE_SERVICES.map((_) => (
                                                <li key={_}>
                                                    <i class="flaticon-check-mark"></i> {_}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                 </div>
                            </div>
                            <p>● Providing personalized, comprehensive, and compassionate care.</p>
                            <p>● Focused on helping patients lead healthier, more fulfilling lives.</p>
                            <p>● Ensuring prompt and accurate diagnoses and treatments.</p>

                            <br/>
                            <b>Committed Primary Care Physicians</b>
                            <p>● Doctors in Titusville offers high-quality, personalized healthcare.</p>
                            <p>● Doctors are dedicated to a patient-centered approach.</p>
                            <p>● Physicians take time to fully understand each patient’s health needs.</p>
                            <p>● Experienced physicians equipped to handle a wide range of health concerns.</p>


                            <div class="services-details-image location-img">
                                <img src="/assets/img/doctor-titsuville.jpg" alt="image" />
                            </div>
                        </div>
                    </div>
                    <RightSideComponent location={location} />
                </div>
            </div>
        </section>
    );
};

export default LocationPage;
