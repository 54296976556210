import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import classnames from 'classnames';

import { PHONE_NUMBERS, EMAILS, SOCIAL_MEDIA, NAV_BAR } from '../config';

import '../scss/header.scss';

const Header = () => {
  const pathname = useLocation().pathname;

  return (
    <header className="header-area">
      <div className="top-header">
        <div className="container col-lg-9">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <ul className="header-contact-info">
                <li>
                  <i className="far fa-clock"></i> Mon - Fri 09:00 - 17:00
                </li>
                <li>
                  <i className="fas fa-phone"></i> Call Us:{' '}
                  <a href={`tel:${PHONE_NUMBERS[0]}`}>{[PHONE_NUMBERS[0]]}</a>
                </li>
                <li>
                  <i className="far fa-paper-plane"></i>{' '}
                  <a href={`mailto:${EMAILS[0]}`}>{EMAILS[0]}</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4">
              <div className="header-right-content">
                <ul className="top-header-social">
                  {SOCIAL_MEDIA.map((sMedia) => (
                    <li key={sMedia.type}>
                      <a href={sMedia.link} target="_blank">
                        <i className={`fab fa-${sMedia.type}`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-area">
        <div className="fovia-responsive-nav">
          <div className="container">
            <div className="fovia-responsive-menu">
              <div className="logo">
                <Link to="/">
                  <img src="/assets/img/logo.png" alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="fovia-nav">
          <div className="container col-lg-9">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to="/">
                <img src="/assets/img/logo.png" alt="logo" className="logo" />
              </Link>

              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  {NAV_BAR.slice(0, NAV_BAR.length - 1).map((navBar) =>
                    !navBar.subMenu ? (
                      <li className="nav-item" key={navBar.link}>
                        {navBar.external ? (
                          <a
                            href={navBar.link}
                            target={navBar.external ? '_blank' : null}
                            className={classnames('nav-link', {
                              active: navBar.link === pathname,
                            })}
                          >
                            {navBar.text}
                          </a>
                        ) : (
                          <Link
                            to={navBar.link}
                            target={navBar.external ? '_blank' : null}
                            className={classnames('nav-link', {
                              active: navBar.link === pathname,
                            })}
                          >
                            {navBar.text}
                          </Link>
                        )}
                      </li>
                    ) : (
                      <li
                        className={classnames('nav-item', {
                          active: pathname.includes(navBar.link),
                        })}
                      >
                        <Link to={navBar.link} className="nav-link">
                          {navBar.text}
                        </Link>
                        <ul className="dropdown-menu">
                          {navBar.subMenu.map((subMenu) => (
                            <li className="nav-item">
                              {subMenu.external ? (
                                <a href={subMenu.link} target="_blank">
                                  {subMenu.text}{' '}
                                </a>
                              ) : (
                                <Link
                                  to={`${navBar.link}${subMenu.link}`}
                                  className={classnames('nav-link', {
                                    active:
                                      `${navBar.link}${subMenu.link}` ===
                                      pathname,
                                  })}
                                >
                                  {subMenu.text}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  )}
                </ul>

                <a
                  href="#appointment-area"
                  className="btn btn-primary justify-self-end header-contact-us-btn"
                >
                  Contact Us
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
