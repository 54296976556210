import React, { useEffect } from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';

import logo from './logo.svg';

import Header from './components/header';
import Footer from './components/footer';

import Home from './pages/home';
import Doctor from './pages/doctor';
import CardioRespiratoryHealth from './pages/services/cardio_respiratory_health';
import GeriatricMedicalCare from './pages/services/geriatric_medical_care';
import TelemedicineAppointments from './pages/services/telemedicine_appointments';
import Vaccinations from './pages/services/vaccinations_immunizations';
import InternalMedicine from './pages/services/internal_medicine';
import WeightLoss from './pages/weight-loss';
import InsuranceIndex from './pages/insurance';
import UninsuredPatients from './pages/insurance/uninsured';
import Dashboard from './pages/dashboard';
import PrivacyPolicy from './pages/privacy-policy';
import LocationPage from './pages/location';

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    window.scrollBy({ behavior: 'smooth', top: 0, left: 0 });
  }, [location]);
}

function SubRouter() {
  usePageViews();

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route
        path="/weight-loss"
        exact
        component={WeightLoss}
      />
      <Route exact path="/insurance">
        <Redirect to="/insurance/patients-with-insurance" />
      </Route>
      <Route
        path="/insurance/patients-with-insurance"
        exact
        component={InsuranceIndex}
      />
      <Route
        path="/insurance/patients-without-insurance"
        exact
        component={UninsuredPatients}
      />
      <Route path="/doctor/dr-tanmay-patel-md" exact component={Doctor} />
      <Route
        path="/medical-cares/internal-medicine"
        exact
        component={InternalMedicine}
      />
      <Route
        path="/medical-cares/vaccination-and-immunizations"
        exact
        component={Vaccinations}
      />
      <Route
        path="/medical-cares/telemedicine-appointments"
        exact
        component={TelemedicineAppointments}
      />
      <Route
        path="/medical-cares/geriatric-medical-care"
        exact
        component={GeriatricMedicalCare}
      />
      <Route
        path="/medical-cares/cardio-respiratory-health"
        exact
        component={CardioRespiratoryHealth}
      />
      <Route exact path="/medical-cares">
        <Redirect to="/medical-cares/internal-medicine" />
      </Route>
      <Route exact path="/staff">
        <Redirect to="/doctor/dr-tanmay-patel-md" />
      </Route>
      <Route
        path="/admin/dashboard"
        exact
        component={Dashboard}
      />
      <Route
        path="/privacy-policy"
        exact
        component={PrivacyPolicy}
      />
      <Route
        path="/location/florida/titusville"
        exact
        component={LocationPage} 
      />
      <Route
        path="*"
        exact
        component={() => <h1 className="coming-soon"> Coming Soon...</h1>}
      />
    </Switch>
  );
}

function App() {
  useEffect(() => {
    axios.post('//ncmd-totalmd.herokuapp.com/totalmdcare/query', {});
  }, []);

  return (
    <div className="App">
      <Router>
        <Header />
        <SubRouter />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
